@import "base";
.m-landing-container {
  background-image: url("../assets/images/landing-bg.svg");
  padding-left: $m-base-padding-left;
  padding-right: $m-base-padding-right;
  padding-top: $m-base-padding-top;
  background-size: cover;
  padding-bottom: 60px;
  overflow-x: hidden;
}

.m-navbar-logo {
  width: 37.71px;
  height: 33px;
}

.m-navbar-logo-text {
  font-family: $secondary-font;
  margin: 0;
  margin-left: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 15.7327px;
  line-height: 100%;
  width: 95px;
}

.m-navbar {
  display: flex;
  justify-content: space-between;
}

.m-navbar-left,
.m-navbar-right {
  display: flex;
  align-items: center;
}

.m-navbar-menu {
  width: 24px;
  height: 19px;
  cursor: pointer;
  user-select: none;
  z-index: 1;
}

.m-navbar-menu-close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  top: 40px;
  right: 30px;
  position: absolute;
}

.m-navbar-modal {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  transform: translatex(100%);
  transition: transform 0.4s ease-in-out;
  z-index: 999;
  overflow: hidden;
}

.m-navbar-modal-show {
  display: flex;
  transform: translatex(0%);
}

.m-navbar-modal-hidden {
  display: none;
}

.m-navbar-items-container {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.m-navbar-items {
  font-family: $tertiary-font;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  width: 75%;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  margin: 0;
  &:focus {
    background-color: rgba(255, 255, 255, 0.06);
  }
}

.m-navbar-items-container a {
  display: flex;
  align-self: center;
  text-decoration: none;
  margin: 56px 0 100px;
}

.m-navbar-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 32px;
  color: #ffffff;
  padding: 16px 32px;
  width: 90vw;
  background: transparent;
}

.m-hero-section {
  margin: auto;
  text-align: center;
  margin-top: 110px;
}

.m-hero-section-text {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 170%;
}

.m-hero-section-text-bottom {
  margin-top: 40px;
}

.m-landing-separator {
  width: 100%;
}

.m-section-separator-img {
  width: 100%;
  height: 100%;
}

.m-hero-section-scroll-down {
  margin-top: 170px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.m-companies-list-container {
  display: flex;
  // width: 80%;
  justify-content: space-between;
  margin: auto;
  margin-top: 80px;
}

.m-companies-list-container img {
  filter: grayscale(100%);
  max-width: 120px;
}

// work section
.m-work-container {
  background-image: url("../assets/images/work-bg.png");
  padding-left: $m-base-padding-left;
  padding-right: $m-base-padding-right;
  padding-top: 56px;
  background-size: cover;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 58px;
}

.m-work-info-heading,
.m-about-info-heading {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
  color: #373737;
  margin: 0;
}

.m-work-info-details,
.m-about-info-details-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #0e0e0e;
  margin: 0;
  margin-top: 20px;
}

.m-work-project-card {
  margin-top: 64px;
}

.m-work-project-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.m-work-project-heading {
  font-family: $baskerville-font;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 120%;
  margin: 0;
  margin-top: 8px;
  color: #242424;
}

.m-work-project-details {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  margin: 0;
}

.m-work-project-details-container {
  margin-top: 24px;
}

.m-work-project-details-row {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.m-work-project-tags-container {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
}

.m-work-project-tags {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
  border: 1px solid #242424;
  box-sizing: border-box;
  border-radius: 56px;
  padding: 8px 24px;
  margin-right: 16px;
  margin-top: 16px;
}

.m-work-project-tags-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: #242424;
  margin: 0;
}

// about section

.m-about-container,
.m-about-container-2 {
  background-image: url("../assets/images/work-bg.png");
  padding-left: $m-base-padding-left;
  padding-right: $m-base-padding-right;
  padding-top: 50px;
  background-size: cover;
  height: 100%;
}

.m-about-container-2 {
  padding-top: 56px;
}

.m-about-details {
  display: flex;
  flex-direction: column;
}

.m-about-info {
  min-width: 342px;
}

.m-about-info-details {
  flex-grow: 1;
  padding-bottom: 30px;
  justify-content: flex-end;
  display: flex;
}

.m-about-info-details-container {
  display: flex;
  flex-direction: column;
}

.m-about-info-chips {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}

.m-about-info-chips div {
  margin-right: 16px;
  margin-bottom: 16px;
}

.m-about-info-chips div p {
  font-size: 12px;
  line-height: 160%;
}

.m-about-details-img-text-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.m-about-details-img-text {
  flex: 45%;
}

.m-about-details-img {
  flex: 30%;
}

.m-about-details-img img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.m-about-info-heading {
  margin-top: 16px;
}

.m-about-slider-container {
  overflow-x: scroll;
  max-height: 195px !important;
  overflow-y: hidden;
}

.m-about-slider-slides {
  background: #cfcfcf;
}

.m-about-slider-container > .slick-slider > .slick-list > .slick-track {
  max-height: 317px !important;
  transform: translate3d(-317px, 0px, 0px);
}

.m-about-slider-container > .slick-slider > .slick-list > .slick-track div {
  margin-right: 12px !important;
  min-width: 280px !important;
  height: 195px !important;
}

.m-about-usp-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.m-about-usp-card-outer {
  width: 100%;
  box-sizing: border-box;
  margin: 0.5%;
  margin-bottom: 24px;
  background: #ffffff;
  border: 1px solid rgba(206, 206, 206, 0.28);
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  position: relative;
}

.m-about-usp-card-inner-div {
  padding: 18px;
}

.m-about-usp-card-heading {
  font-family: $tertiary-font;
  color: #0e0e0e;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}

.m-about-usp-card-tags-container {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
}

.m-about-usp-card-tags {
  padding: 6px 18px;
  border: 1px solid #373737;
  box-sizing: border-box;
  border-radius: 56px;
  margin-right: 10px;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
}

.m-about-testimonials-container {
  padding-top: 50px;
}

.m-about-testimonials-heading {
  font-family: $primary-font;
  text-transform: capitalize;
  color: #373737;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
}

.m-about-testimonials-details-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.m-about-testimonials-details {
  font-family: $tertiary-font;
  color: #1e1e1e;
  margin: 0;
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
}

.m-about-testimonials-cards-section {
  margin-top: 24px;
  overflow: hidden;
}

.m-about-testimonials-card {
  background: rgba(245, 245, 245, 0.2);
  border: 1px solid #cecece;
  box-sizing: border-box;
  margin-right: 26px;
  padding: 24px;
}

.m-about-testimonials-card-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.m-about-testimonials-person-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.m-about-testimonials-arrows {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 30px;
}

.m-about-testimonials-arrows img {
  margin-left: 40px;
  cursor: pointer;
}

.m-about-testimonials-person-details {
  margin-left: 16px;
  padding-top: 5px;
}

.m-about-testimonials-person-text,
.m-about-testimonials-person-profile {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #2e2e2e;
  margin-bottom: 4px;
}

.m-about-testimonials-person-profile {
  color: #808080;
}

.m-about-testimonials-person-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

// Journey section
.m-journey-container {
  padding-left: $m-base-padding-left;
  padding-right: $m-base-padding-right;
  padding-top: 64px;
  color: #fff;
  background-image: url("../assets/images/journey-bg.png");
  background-size: cover;
}

.m-journey-header-text {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 160%;
  margin: 0;
}

.m-journey-header-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.m-journey-header-tags > div {
  margin-right: 16px;
  margin-bottom: 16px;
}

.m-journey-path {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-bottom: 70px;
  position: relative;
}

.m-journey-path-sections {
  margin: 24px 0;
  &:not(:last-child):before {
    content: "";
    position: absolute;
    border-left: 1px solid #ffffff;
    height: 100%;
    left: 21px;
    transform: translateY(1%);
  }
}

.m-journey-path-sections-right {
  justify-content: flex-end;
  display: flex;
}

.m-journey-path-line-dots-container {
  padding: 8px 16px 0 0;
}

.m-journey-path-line-dots {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 14px solid rgba(0, 0, 0, 0.8);
  position: relative;
}

.m-journey-path-line-dots-present::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 2;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}

.m-work-tile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.m-work-tile-right {
  text-align: right;
}

.m-work-tile-heading,
.m-work-tile-heading-present,
.m-work-tile-sub-heading-present,
.m-work-tile-sub-heading,
.m-work-tile-details {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  margin: 0;
}
.m-work-tile-heading {
  font-size: 18px;
}
.m-work-tile-heading-present {
  font-size: 20px;
}
.m-work-tile-sub-heading {
  font-size: 15px;
}
.m-work-tile-sub-heading-present {
  font-size: 18px;
}
.m-work-tile-details {
  font-size: 14px;
  opacity: 0.5;
  font-weight: normal;
}

// contact section
.m-contact-container {
  background-image: url("../assets/images/journey-bg.png");
  padding-left: $m-base-padding-left;
  padding-right: $m-base-padding-right;
  padding-top: $m-base-padding-top;
  background-size: cover;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.m-contact-footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.m-contact-sm-links {
  color: #ffffff;
  font-family: $tertiary-font;
  font-size: $primary-font-size;
  text-decoration: none;
  margin: 0;
  margin-bottom: 22px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
}

.m-contact-connect {
  margin-bottom: 54px;
}

.m-connect-btn,
.m-connect-btn-filled {
  padding: 16px 64px;
  border: 2px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: $tertiary-font;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 270px;
}

.m-connect-btn {
  margin-top: 16px;
}

.m-connect-btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.m-connect-btn-filled {
  background-color: #ffffff;
  padding: 16px 64px;
  color: #000000;
}

.m-contact-connect-text {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 120%;
  color: #ffffff;
  overflow-wrap: break-word;
}

.m-contact-sm-madeby {
  display: flex;
  flex-grow: 1;
  font-family: $tertiary-font;
  font-size: $primary-font-size;
  color: #ffffff;
  margin: 0;
}

.m-contact-sm-madeby-name {
  margin: 0;
  margin-left: 6px;
  color: #ffffff;
}
