@import url("https://fonts.googleapis.com/css2?family=Hepta+Slab:wght@400;600&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:wght@400;600&display=swap");

$imported-once-files: () !default;

@function not-imported($name) {
  $module_index: index($imported-once-files, $name);
  @if (($module_index == null) or ($module_index == false)) {
    $imported-once-files: append($imported-once-files, $name) !global;
    @return true;
  }
  @return false;
}

$primary-color: blue;
$secondary-color: red;
$primary-font: "Libre Baskerville", serif;
$secondary-font: "Hepta Slab", serif;
$tertiary-font: "Work Sans", sans-serif;
$baskerville-font: "Baskerville", serif;
$primary-font-size: 14px;

$l-base-padding-left: 85px;
$l-base-padding-right: 85px;
$l-base-padding-top: 54px;

$m-base-padding-left: 24px;
$m-base-padding-right: 24px;
$m-base-padding-top: 46px;

@mixin primary-font-family {
  font-family: $primary-font;
  font-size: $primary-font-size;
}

$margin-values: 5, 10, 15, 20;
$margin-dirs: "ml", "mr", "mt", "mb", "mx", "my";
@mixin margin($dir, $var) {
  @if $dir == "mt" {
    margin-top: $var;
  } @else if $dir == "mr" {
    margin-right: $var;
  } @else if $dir == "mb" {
    margin-bottom: $var;
  } @else if $dir == "ml" {
    margin-left: $var;
  } @else if $dir == "mx" {
    margin-left: $var;
    margin-right: $var;
  } @else if $dir == "my" {
    margin-top: $var;
    margin-bottom: $var;
  }
}

$padding-values: 5, 10, 15, 20;
$padding-dirs: "pl", "pr", "pt", "pb", "px", "py";
@mixin padding($dir, $var) {
  @if $dir == "pt" {
    margin-top: $var;
  } @else if $dir == "pr" {
    margin-right: $var;
  } @else if $dir == "pb" {
    margin-bottom: $var;
  } @else if $dir == "pl" {
    margin-left: $var;
  } @else if $dir == "px" {
    margin-left: $var;
    margin-right: $var;
  } @else if $dir == "py" {
    margin-top: $var;
    margin-bottom: $var;
  }
}
