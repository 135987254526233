@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import "mixins";

@each $i in $margin-dirs {
  @each $j in $margin-values {
    .#{$i}-#{$j} {
      @include margin(#{$i}, #{$j}px);
    }
  }
}

@each $i in $padding-dirs {
  @each $j in $padding-values {
    .#{$i}-#{$j} {
      @include padding(#{$i}, #{$j}px);
    }
  }
}

.italic {
  font-style: italic;
}

.chip-btn {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  border: 1px solid #242424;
  box-sizing: border-box;
  border-radius: 56px;
  width: fit-content;
  font-size: 12px;
}

.chip-btn-dark {
  width: fit-content;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  );
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 56px;
  font-size: 12px;
}

.chip-btn-text,
.chip-btn-dark-text {
  padding: 10px 24px;
  margin: 0;
}
.chip-btn-text {
  color: #242424;
}
.chip-btn-dark-text {
  color: #fff;
}
.reverse-arrows {
  transform: rotate(180deg);
}