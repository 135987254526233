@import "base";

// desktop styles
.l-blog-container {
  background-image: url("../assets/images/landing-bg.svg");
  padding: 40px 250px;
}

.l-blog-content-container {
  font-family: $tertiary-font;
  line-height: 145%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.l-blog-content-container div {
  margin: 10px 0;
  padding: 5px 0;
}

.l-content-h {
  font-size: 36px;
  font-weight: bolder;
}

.l-content-sh {
  font-size: 26px;
  font-weight: bold;
  margin-top: 40px !important;
}

.l-content-ssh {
  font-size: 22px;
  font-weight: 600;
}

.l-content-p {
  font-family: $primary-font;
  font-size: 20px;
  line-height: 36px;
}

.l-content-ip {
  font-family: $primary-font;
  font-size: 20px;
  line-height: 36px;
  font-style: italic;
}

.l-content-sq {
  font-family: $primary-font;
  font-size: 18px;
  line-height: 36px;
  padding-left: 10px !important;
  border-left: 4px solid black;
}

.l-content-q {
  font-family: $secondary-font;
  font-size: 36px;
  color: gray;
  line-height: 50px;
  padding: 30px 40px !important;
}

.l-content-sp {
  display: flex;
  border-top: 9px dotted black;
  width: 4%;
  justify-content: center;
  align-self: center;
  margin: 50px 0 !important;
}

.l-content-ul li {
  font-family: $primary-font;
  font-size: 20px;
  line-height: 50px;
  margin: 12px 0;
}

.l-blog-img-container-i {
  text-align: center;
}

// Mobile styles
@import "base";

// desktop styles
.m-blog-container {
  background-image: url("../assets/images/landing-bg.svg");
  padding: 20px 30px;
}

.m-blog-content-container {
  font-family: $tertiary-font;
  line-height: 145%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.m-blog-content-container div {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding: 5px 0;
}

.m-content-h {
  font-size: 30px;
  font-weight: bolder;
}

.m-content-sh {
  font-size: 22px;
  font-weight: bold;
}

.m-content-ssh {
  font-size: 18px;
  font-weight: 600;
}

.m-content-p {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 28px;
}

.m-content-ip {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 28px;
  font-style: italic;
}

.m-content-sq {
  font-family: $primary-font;
  font-size: 14px;
  line-height: 28px;
  padding-left: 10px !important;
  border-left: 4px solid black;
}

.m-content-q {
  font-family: $secondary-font;
  font-size: 18px;
  color: gray;
  line-height: 28px;
}

.m-content-sp {
  display: flex;
  border-top: 9px dotted black;
  width: 14%;
  justify-content: center;
  align-self: center;
  margin: 30px 0 !important;
}

.m-content-ul {
  margin: 0;
}

.m-content-ul li {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 28px;
  margin: 12px 0;
}

.m-blog-img-container-i {
  text-align: center;
}

.m-blog-img-container-i img {
  width: 100% !important;
  height: 100% !important;
}