@import "base";
.l-landing-container {
  background-image: url("../assets/images/landing-bg.svg");
  padding-left: $l-base-padding-left;
  padding-right: $l-base-padding-right;
  padding-top: $l-base-padding-top;
  background-size: cover;
}

.l-navbar-logo {
  width: 48px;
  height: 42px;
}

.l-navbar-logo-text {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-left: 12px;
  width: 118px;
  line-height: 100%;
}

.l-navbar {
  display: flex;
  justify-content: space-between;
}

.l-navbar-left,
.l-navbar-right {
  display: flex;
}

.l-navbar-left {
  margin-top: 6px;
}

.l-navbar-items {
  margin-left: 32px;
  // margin-right: 32px;
  font-family: $tertiary-font;
  font-size: 18px;
  cursor: pointer;
}

.l-navbar-btn,
.l-work-info-call-btn {
  padding: 16px 32px;
  border: 1px solid #0e0e0e;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: $tertiary-font;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 32px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.l-work-info-call-btn {
  margin-left: 0;
  margin-top: 32px;
  background-color: transparent;
}

.l-work-info-call-btn:hover {
  background-color: #0e0e0e;
  color: #ffffff;
}

.l-navbar-btn:hover {
  background-color: #0e0e0e;
  color: #ffffff;
  cursor: pointer;
}

.l-hero-section {
  width: 810px;
  margin: auto;
  text-align: center;
  margin-top: 120px;
}

.l-hero-section-text {
  font-family: $primary-font;
  font-size: 26px;
  line-height: 160%;
}

.l-hero-section-text-bottom {
  margin-top: 40px;
}

.l-landing-separator {
  width: 100%;
}

.l-section-separator-img {
  width: 102vw;
}

.l-hero-section-scroll-down {
  margin-top: 170px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
}

.l-companies-list-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: auto;
  margin-top: 72px;
}

.l-companies-list-container img {
  filter: grayscale(100%);
  max-width: 170px;
}

// work section

.l-work-container {
  background-image: url("../assets/images/work-bg.png");
  padding-left: $l-base-padding-left;
  padding-right: $l-base-padding-right;
  padding-top: 230px;
  background-size: cover;
  height: 100%;
  display: flex;
  position: relative;
}

.l-work-info {
  position: sticky;
  top: 50px;
  padding-bottom: 90px;
  align-self: flex-start;
  width: 342px;
}

.l-work-projects {
  flex-grow: 1;
  padding-left: 120px;
  padding-bottom: 30px;
}

.l-work-info-heading,
.l-about-info-heading {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 130%;
  color: #373737;
  margin: 0;
}

.l-work-info-details,
.l-about-info-details-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #0e0e0e;
  margin: 0;
  margin-top: 20px;
}

.l-work-project-card {
  max-width: 810px;
  margin-bottom: 64px;
}

.l-work-project-img {
  width: 100%;
  height: 100%;
}

.l-work-project-heading {
  font-family: $baskerville-font;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 120%;
  margin: 0;
  margin-top: 8px;
  color: #242424;
}

.l-work-project-details {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  margin: 0;
}

.l-work-project-details-container {
  margin-top: 16px;
}

.l-work-project-details-row {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.l-work-project-tags-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.l-work-project-tags {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
  border: 1px solid #242424;
  box-sizing: border-box;
  border-radius: 56px;
  padding: 8px 24px;
  margin-right: 16px;
}

.l-work-project-tags-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
  color: #242424;
  margin: 0;
}

// Journey section
.l-journey-container {
  padding-left: $l-base-padding-left;
  padding-right: $l-base-padding-right;
  padding-top: 100px;
  color: #fff;
  background-image: url("../assets/images/journey-bg.png");
  background-size: cover;
}

.l-journey-header-text {
  font-family: $primary-font;
  font-size: 46px;
  line-height: 160%;
  margin: 0;
  text-align: center;
}

.l-journey-header-tags {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  justify-content: center;
  margin: auto;
  margin-top: 24px;
}

.l-journey-header-tags > div {
  margin: 8px;
}

.l-journey-path {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  padding-bottom: 110px;
  position: relative;
}

.l-journey-path-sections {
  width: 800px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.l-journey-path-sections-right {
  justify-content: flex-end;
  display: flex;
}

.l-journey-path-line {
  position: absolute;
  width: 630px;
  border: 1px solid #ffffff;
  transform: rotate(90deg);
  top: 41%;
}

.l-journey-path-line-dots {
  width: 16.73px;
  height: 16.73px;
  background-color: #ffffff;
  position: absolute;
  border-radius: 50%;
  border: 14px solid rgba(0, 0, 0, 0.8);
  bottom: -23px;
  left: -5%;
}

.l-journey-path-line-dots-1::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: 2;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(2.5, 2.5, 1);
  }
}

.l-journey-path-line-dots-2 {
  left: 20%;
}
.l-journey-path-line-dots-3 {
  left: 45%;
}
.l-journey-path-line-dots-4 {
  left: 70%;
}
.l-journey-path-line-dots-5 {
  left: 95%;
}

.l-work-tile {
  display: flex;
  flex-direction: column;
}

.l-work-tile-right {
  text-align: right;
  min-height: 130px;
}

.l-work-tile-heading,
.l-work-tile-heading-present,
.l-work-tile-sub-heading-present,
.l-work-tile-sub-heading,
.l-work-tile-details {
  font-family: $tertiary-font;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: #ffffff;
  margin: 0;
  min-width: 345px;
  max-width: 345px;
  list-style-type: none;
}
.l-work-tile-heading-present {
  font-size: 22px;
}
.l-work-tile-sub-heading {
  font-size: 15px;
}
.l-work-tile-sub-heading-present {
  font-size: 16px;
}
.l-work-tile-details {
  font-size: 14px;
  opacity: 0.5;
  font-weight: normal;
}

// about section

.l-about-container,
.l-about-container-2 {
  background-image: url("../assets/images/work-bg.png");
  padding-left: $l-base-padding-left;
  padding-right: $l-base-padding-right;
  padding-top: 100px;
  background-size: cover;
  height: 100%;
}

.l-about-container-2 {
  padding-top: 56px;
}

.l-about-details {
  display: flex;
}

.l-about-info {
  min-width: 342px;
}

.l-about-info-details {
  flex-grow: 1;
  padding-bottom: 30px;
  justify-content: flex-end;
  display: flex;
}

.l-about-info-details-container {
  display: flex;
  flex-direction: column;
}

.l-about-info-chips {
  margin-top: 40px;
  margin-bottom: 25px;
  max-width: 430px;
  display: flex;
  flex-wrap: wrap;
}

.l-about-info-chips div {
  margin-right: 16px;
  margin-bottom: 16px;
}

.l-about-details-img {
  width: 320px;
  min-width: 250px;
  height: 345px;
  margin-left: 64px;
}

.l-about-details-img img {
  width: 100%;
  height: 100%;
}

.l-about-info-heading,
.l-about-info-details-text {
  margin-top: 0;
  max-width: 430px;
}

.l-about-slider-container {
  overflow-x: scroll;
  max-height: 317px !important;
  overflow-y: hidden;
}

.l-about-slider-slides {
  background: #cfcfcf;
}

.l-about-slider-container > .slick-slider > .slick-list > .slick-track {
  max-height: 317px !important;
  transform: translate3d(-1130px, 0px, 0px);
}

.l-about-slider-container > .slick-slider > .slick-list > .slick-track div {
  margin-right: 16px !important;
  min-width: 550px !important;
  height: 317px !important;
}

.l-about-usp-container {
  display: flex;
  flex-wrap: wrap;
}

.l-about-usp-card-outer {
  max-width: 48%;
  min-width: 45%;
  box-sizing: border-box;
  margin: 0.5%;
  background: #ffffff;
  border: 1px solid rgba(206, 206, 206, 0.28);
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.l-about-usp-card-outer:hover {
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
  background: #272525;
  border: 1px solid rgba(206, 206, 206, 0.28);
  color: #fff;
}

.l-about-usp-card-outer:hover .l-about-usp-card-heading {
  color: #fff;
}

.l-about-usp-card-inner-div {
  padding: 40px;
}

.l-about-usp-card-heading {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 140%;
  color: #0e0e0e;
  margin: 0;
}

.l-about-usp-card-tags-container {
  padding-top: 40px;
  display: flex;
}

.l-about-usp-card-tags {
  padding: 8px 24px;
  border: 1px solid #373737;
  box-sizing: border-box;
  border-radius: 56px;
  margin-right: 16px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  text-align: center;
}

.l-about-testimonials-container {
  padding-top: 100px;
}

.l-about-testimonials-heading {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 64px;
  text-transform: capitalize;
  color: #373737;
  margin: 0;
}

.l-about-testimonials-details-section {
  display: flex;
  justify-content: space-between;
}

.l-about-testimonials-details {
  width: 50%;
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #1e1e1e;
  margin: 0;
  margin-top: 24px;
}

.l-about-testimonials-cards-section {
  margin-top: 40px;
  overflow: hidden;
  padding-bottom: 60px;
}

.l-about-testimonials-card {
  background: rgba(245, 245, 245, 0.2);
  border: 1px solid #cecece;
  box-sizing: border-box;
  margin-right: 26px;
  padding: 24px;
}

.l-about-testimonials-card-text {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.l-about-testimonials-person-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.l-about-testimonials-arrows {
  padding-top: 30px;
}

.l-about-testimonials-arrows img {
  margin-left: 40px;
  cursor: pointer;
}

.l-about-testimonials-person-details {
  margin-left: 16px;
  padding-top: 5px;
}

.l-about-testimonials-person-text,
.l-about-testimonials-person-profile {
  font-family: $tertiary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #2e2e2e;
  margin-bottom: 4px;
}

.l-about-testimonials-person-profile {
  color: #808080;
}

.l-about-testimonials-person-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

// contact section
.l-contact-container {
  background-image: url("../assets/images/journey-bg.png");
  padding-left: $l-base-padding-left;
  padding-right: $l-base-padding-right;
  padding-top: $l-base-padding-top;
  background-size: cover;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.l-contact-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  align-content: space-between;
}

.l-contact-sm-links {
  color: #ffffff;
  font-family: $tertiary-font;
  font-size: $primary-font-size;
  text-decoration: none;
  margin: 0;
}

.l-contact-connect {
  margin-bottom: 130px;
}

.l-connect-btn,
.l-connect-btn-filled {
  padding: 16px 32px;
  border: 2px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 32px;
  font-family: $tertiary-font;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.l-connect-btn {
  margin-left: 24px;
}

.l-connect-btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.l-connect-btn-filled {
  background-color: #ffffff;
  padding: 16px 64px;
  color: #000000;
}

.l-contact-connect-text {
  font-family: $primary-font;
  font-size: 46px;
  line-height: 103.8%;
  color: #ffffff;
}

.l-contact-sm-madeby {
  display: flex;
  flex-grow: 1;
  font-family: $tertiary-font;
  font-size: $primary-font-size;
  color: #ffffff;
  margin: 0;
}

.l-contact-sm-madeby-name {
  margin: 0;
  margin-left: 6px;
  color: #ffffff;
}
